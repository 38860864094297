import { Fragment } from "react"
import logoWhiteVariant from "./logo-white.png"
import logo from "./logo.png"
import hero from "./hero.jpg"
import remixContestBanner from "./remix-contest-banner.jpg"
import remixContestLogos from "./wvrps-x-audius-logos.png"

function decodeUrl(url: string) {
  try {
    return atob(url)
  } catch (_) {
    return ""
  }
}

function StemDownloadButton() {
  const params = new URLSearchParams(window.location.search)
  const id = params.get("id")
  const url = decodeUrl(params.get("url") || "")

  return (
    <button
      onClick={() => window.location.assign(url)}
      disabled={!id || !url}
      style={{ textTransform: "uppercase" }}
    >
      {url && id ? `Download stems for WVRP #${id || ""}` : "No WVRP found, please check your link"}
    </button>
  )
}

function App() {
  if (window.location.pathname === "/remix-contest") {
    return <RemixContestApp />
  } else {
    return <StemsApp />
  }
}

function StemsApp() {
  return (
    <Fragment>
      <header
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 94,
          padding: "0 3vw",
          backgroundColor: "#1C1C1C",
          color: "white",
          zIndex: 9999,
        }}
      >
        <a href="https://www.warpsound.ai">
          <img alt="WarpSound logo" src={logo} width="240px" height="auto" />
        </a>
        <a href="https://www.warpsound.ai">Home</a>
      </header>
      <main style={{ flex: "1 0 auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // Space for header
            marginTop: 94,
          }}
        >
          <div
            style={{
              background: `url(${hero})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: 400,
              position: "relative",
              flexShrink: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <img alt="" src={logoWhiteVariant} width="50%" style={{ maxWidth: 800 }} />
              <div
                style={{
                  marginTop: 40,
                  fontFamily: "Hanson Bold",
                  fontSize: "3.5vw",
                  color: "#F7F7F7",
                  textTransform: "uppercase",
                }}
              >
                Download Stems
              </div>
            </div>
          </div>

          {/* Legal */}
          <div style={{ display: "flex", justifyContent: "center", flexShrink: 0 }}>
            <div style={{ padding: "20px 20px", fontSize: 14, maxWidth: 800, lineHeight: "24px" }}>
              <p>Thanks for requesting your WVRP stems!</p>
              <p>
                By downloading the stems below you acknowledge and agree that these stems are
                considered part of the applicable WVRP's 'Creative Work', as defined and licensed to
                you in the WarpSound{" "}
                <a href="https://www.warpsound.ai/terms" target="_blank" rel="noreferrer">
                  Terms of Use
                </a>
                . Once you sell or transfer your WVRP you no longer have a license to use these
                stems in any future work, but are not prohibited from using them in work you created
                while you owned the WVRP. For any tracks you wish to commercialize please remember
                to credit the use of the WVRPS music (which includes use of the stems), along the
                lines of stating that your track “Contains a sample of WVRP #1234 by WarpSound” -
                obviously replacing 1234 with the serial number of your WVRP.
              </p>
              <p>
                If you have additional questions about music usage rights that either the{" "}
                <a href="https://www.warpsound.ai/terms" target="_blank" rel="noreferrer">
                  Terms of Use
                </a>{" "}
                or the Rights section of the{" "}
                <a href="https://www.warpsound.ai/#faq" target="_blank" rel="noreferrer">
                  FAQs
                </a>{" "}
                don't answer, please reach out to us on Discord or by emailing
                terms@authentic-artists.ai and we'll do our best to help.
              </p>
              <p>We're excited to see what you create!</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 10px",
            }}
          >
            <StemDownloadButton />
          </div>
        </div>
      </main>
      <footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          flexShrink: 0,
          paddingTop: 100,
          paddingBottom: 40,
        }}
      >
        <div style={{ textAlign: "center" }}>
          &#169; 2022 AUTHENTIC ARTISTS. ALL RIGHTS RESERVED.
          <div style={{ display: "inline-block" }}>
            ☆{" "}
            <a target="_blank" rel="noreferrer" href="http://warpsound.ai/terms">
              TERMS & CONDITIONS
            </a>{" "}
            ☆{" "}
            <a target="_blank" rel="noreferrer" href="https://www.warpsound.ai/privacy-policy">
              PRIVACY POLICY
            </a>
          </div>
        </div>
        <div style={{ padding: "20px 20px", textAlign: "center", maxWidth: 800, fontSize: 12 }}>
          DISCLAIMER: WVRPS NFTS ARE DIGITAL COLLECTIBLES. NOTHING ON THIS SITE OR IN ANY WARPSOUND,
          WVRPS OR AUTHENTIC ARTISTS COMMUNICATIONS ARE TO BE CONSIDERED AS FINANCIAL ADVICE.
          POTENTIAL COLLECTORS NEED TO DO THEIR OWN RESEARCH AND EDUCATE THEMSELVES ON + UNDERSTAND
          THE RISKS INVOLVED WITH CRYPTOCURRENCIES AND NFTS AT LARGE.
        </div>
      </footer>
    </Fragment>
  )
}

// TEMPORARY
function RemixContestApp() {
  return (
    <Fragment>
      <header
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 94,
          padding: "0 3vw",
          backgroundColor: "#1C1C1C",
          color: "white",
          zIndex: 9999,
        }}
      >
        <a href="https://www.warpsound.ai">
          <img alt="WarpSound logo" src={logo} width="240px" height="auto" />
        </a>
        <a href="https://www.warpsound.ai">Home</a>
      </header>
      <main style={{ flex: "1 0 auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // Space for header
            marginTop: 94,
          }}
        >
          <div
            style={{
              background: `url(${remixContestBanner})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: 400,
              width: "100%",
              position: "relative",
              flexShrink: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <img alt="" src={remixContestLogos} width="50%" style={{ maxWidth: 800 }} />
              <div
                style={{
                  marginTop: 40,
                  fontFamily: "Hanson Bold",
                  fontSize: "3vw",
                  color: "#F7F7F7",
                  opacity: 0.9,
                  textTransform: "uppercase",
                }}
              >
                Remix Contest Stems
              </div>
            </div>
          </div>

          {/* Legal */}
          <div style={{ display: "flex", justifyContent: "center", flexShrink: 0 }}>
            <div style={{ padding: "20px 20px", fontSize: 14, maxWidth: 800, lineHeight: "24px" }}>
              <p>Welcome to the WVRPS x Audius Remix Contest!&nbsp;</p>
              <p>
                While you are encouraged to use the audio stems associated with a WVRP that you own
                to create a remix, if you do not own a WVRP you may still participate by using the
                audio stems from one of the 3 WarpSound-owned WVRPS here.
              </p>
              <p>
                Please note: By downloading the audio stems associated with WVRP #142, #172, or #179
                (the &ldquo;Contest WVRPS&rdquo;) you hereby acknowledge and agree to the following:
              </p>
              <ol>
                <li>
                  WarpSound is the owner of the Contest WVRPS and reserves all rights not expressly
                  granted below.
                </li>
                <li>
                  WarpSound is granting you a limited, worldwide, non-exclusive, non-transferable
                  and non-sublicenseable, royalty-free sublicense to:
                </li>
                <ol>
                  <li>
                    Use the audio and/or audio stems associated with each applicable Contest WVRP{" "}
                    <span style={{ textDecoration: "underline" }}>solely for the purpose</span> of
                    creating a new track (the &ldquo;WVRPS Remix&rdquo;) that will be entered into
                    the WarpSound/Audius Remix contest (the &ldquo;Contest&rdquo;).
                  </li>
                  <li>
                    Use any such WVRPS Remix that is entered into the Contest for your own personal
                    or commercial use, provided that if you use the track commercially you agree to
                    make commercially reasonable efforts to provide attribution to WarpSound in
                    substantially the following form:{" "}
                    <strong>
                      <em>"Contains a sample of WVRP #123 by WarpSound"</em>
                    </strong>{" "}
                    (replacing 123 with the serial number of the applicable Contest WVRP).
                  </li>
                  <li>
                    Use the artwork of the applicable Contest WVRP solely in conjunction with the
                    promotion of your WVRPS Remix.&nbsp;
                  </li>
                </ol>
                <li>For the avoidance of doubt you may not:</li>
                <ol>
                  <li>
                    Use the artwork of a Contest WVRP on its own unless in conjunction with
                    promoting your WVRPS Remix.
                  </li>
                  <li>Create any derivatives of the artwork.</li>
                  <li>
                    Use the audio or audio stems of a Contest WVRP other than to create a WVRPS
                    Remix. Any demo tracks you create using a Contest WVRP which you do not enter
                    into the Contest are not subject to the sublicense above and cannot be used
                    either personally or commercially.
                  </li>
                </ol>
                <li>
                  You also hereby grant to WarpSound a non-exclusive, perpetual, irrevocable,
                  worldwide, royalty-free, and transferable license to use, perform, display,
                  distribute, modify, translate, copy, and otherwise exploit any WVRPS Remix(es) you
                  enter into the Contest for WarpSound and its affiliates&rsquo; promotional and
                  marketing purposes.&nbsp;
                </li>
                <li>
                  You do not have any right to use any WarpSound or other third-party intellectual
                  property rights (including but not limited to any artwork, music, logos, names, or
                  trademarks) except as they are presented in the Contest WVRPS and thereby subject
                  to the sublicense terms above.
                </li>
              </ol>
              <p>
                If you have additional questions about usage rights please reach out to us on
                Discord or by emailing terms@authentic-artists.ai and we'll do our best to help.
              </p>
              <p>We're excited to see what you create!</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              padding: "0 10px",
            }}
          >
            <div style={{ padding: 10 }}>
              <button
                onClick={() =>
                  window.location.assign(
                    "https://cdn.warpsound.ai/remix-contest-stems/WVRP-142-Stems.zip",
                  )
                }
              >
                WVRP #142 Stems
              </button>
            </div>
            <div style={{ padding: 10 }}>
              <button
                onClick={() =>
                  window.location.assign(
                    "https://cdn.warpsound.ai/remix-contest-stems/WVRP-172-Stems.zip",
                  )
                }
              >
                WVRP #172 Stems
              </button>
            </div>
            <div style={{ padding: 10 }}>
              <button
                onClick={() =>
                  window.location.assign(
                    "https://cdn.warpsound.ai/remix-contest-stems/WVRP-179-Stems.zip",
                  )
                }
              >
                WVRP #179 Stems
              </button>
            </div>
          </div>
        </div>
      </main>
      <footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          flexShrink: 0,
          paddingTop: 100,
          paddingBottom: 40,
        }}
      >
        <div style={{ textAlign: "center" }}>
          &#169; 2022 AUTHENTIC ARTISTS. ALL RIGHTS RESERVED.
          <div style={{ display: "inline-block" }}>
            ☆{" "}
            <a target="_blank" rel="noreferrer" href="http://warpsound.ai/terms">
              TERMS & CONDITIONS
            </a>{" "}
            ☆{" "}
            <a target="_blank" rel="noreferrer" href="https://www.warpsound.ai/privacy-policy">
              PRIVACY POLICY
            </a>
          </div>
        </div>
        <div style={{ padding: "20px 20px", textAlign: "center", maxWidth: 800, fontSize: 12 }}>
          DISCLAIMER: WVRPS NFTS ARE DIGITAL COLLECTIBLES. NOTHING ON THIS SITE OR IN ANY WARPSOUND,
          WVRPS OR AUTHENTIC ARTISTS COMMUNICATIONS ARE TO BE CONSIDERED AS FINANCIAL ADVICE.
          POTENTIAL COLLECTORS NEED TO DO THEIR OWN RESEARCH AND EDUCATE THEMSELVES ON + UNDERSTAND
          THE RISKS INVOLVED WITH CRYPTOCURRENCIES AND NFTS AT LARGE.
        </div>
      </footer>
    </Fragment>
  )
}

export default App
